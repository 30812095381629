<template>
  <p
    ref="outputTextRef"
    class="text-sm text-etBlack-100 bg-etBlue-10 rounded-sm w-fit h-fit py-[1px] px-3 m-0"
    :class="{
      '!text-etGreen-100 !bg-etGreen-20': green,
      '!text-etYellow-100 !bg-etYellow-10': yellow,
      '!text-etRed-100 !bg-etRed-10': red,
      '!text-etBlue-100 !bg-etBlue-10': blue,
      '!bg-white': reverse,
      'font-semibold': bold
    }"
  >
    {{ text }}
  </p>
</template>
<script setup>
import { onMounted, ref, watch } from 'vue'

const props = defineProps({
  text: String | Number,
  green: { type: Boolean, default: false },
  yellow: { type: Boolean, default: false },
  red: { type: Boolean, default: false },
  blue: { type: Boolean, default: false },
  reverse: { type: Boolean, default: false },
  bold: { type: Boolean, default: false },
  color: {
    type: String,
    default: getComputedStyle(document.body).getPropertyValue(
      '--output-text-color'
    )
  },
  background: {
    type: String,
    default: getComputedStyle(document.body).getPropertyValue(
      '--output-text-background'
    )
  }
})

const outputTextRef = ref()

onMounted(() => {
  outputTextRef.value.style.setProperty('--output-text-color', props.color)
  outputTextRef.value.style.setProperty(
    '--output-text-background',
    props.background
  )
})

watch(
  () => props.color,
  (newVal) => {
    outputTextRef.value.style.setProperty('--output-text-color', newVal)
  }
)
watch(
  () => props.background,
  (newVal) => {
    outputTextRef.value.style.setProperty('--output-text-background', newVal)
  }
)
</script>
