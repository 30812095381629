<template>
  <div
    class="tooltipContainer relative"
    :class="{
      'max-w-[300px]': greyTheme && !smallSize,
      'max-w-[200px]': smallSize
    }"
  >
    <div
      id="tooltipTarget"
      ref="tooltipTargetRef"
      class="tooltipTarget w-fit"
      aria-describedby="tooltip"
      @mouseenter="showTooltip"
      @mouseleave="hideTooltip"
    >
      <slot />
    </div>
    <div
      ref="tooltipRef"
      class="tooltip hidden bg-white py-[5px] px-[10px] rounded-sm z-[10000] whitespace-nowrap break-words"
      :class="{
        '!py-[15px] !px-[20px]': largeSize,
        'flex-col !bg-etBlack-10 !whitespace-break-spaces': greyTheme,
        '!bg-etBlack-100 !text-white [&>.textSmall]:!text-white': blackTheme
      }"
      role="tooltip"
    >
      <TextSmall v-if="label">
        {{ label }}
      </TextSmall>
      <TextSmall
        v-if="description"
        :class="{ 'mt-[5px] text-etBlack-80': greyTheme }"
      >
        {{ description }}
      </TextSmall>
      <span v-if="html" v-html="html" />
      <slot name="text" />
      <div
        class="tooltip__arrow [data-show]:inline-block absolute w-[8px] h-[8px] bg-inherit invisible before:visible before:content-[''] before:transform before:rotate-[45deg]"
        data-popper-arrow
      />
    </div>
  </div>
</template>
<script setup>
import TextSmall from '@/components/shared/font/text/TextSmall'
import { createPopper } from '@popperjs/core'
import { onMounted, ref } from 'vue'

const props = defineProps({
  disabled: Boolean,
  label: String,
  position: {
    type: String,
    default: 'right'
  },
  largeSize: {
    type: Boolean,
    default: false
  },
  smallSize: {
    type: Boolean,
    default: false
  },
  greyTheme: {
    type: Boolean,
    default: false
  },
  blackTheme: {
    type: Boolean,
    default: false
  },
  description: {
    type: String,
    default: null
  },
  // A list of all the available modifier and how to use them can be found on the link below:
  // https://popper.js.org/docs/v2/modifiers/
  modifiers: {
    default: () => [],
    type: Array
  },
  html: String
})

const tooltipTargetRef = ref()
const tooltipRef = ref()
const popperInstance = ref(null)

onMounted(() => {
  popperInstance.value = createPopper(
    tooltipTargetRef.value,
    tooltipRef.value,
    {
      placement: props.position,
      modifiers: [
        ...props.modifiers,
        {
          name: 'offset',
          options: {
            offset: [0, 8]
          }
        }
      ]
    }
  )
})

function showTooltip() {
  if (props.disabled) {
    return
  }
  if (tooltipRef.value) {
    tooltipRef.value.setAttribute('data-show', '')
    tooltipRef.value.style.display = 'flex'
  }
  // We need to tell Popper to update the tooltip position
  // after we show the tooltip, otherwise it will be incorrect
  popperInstance.value.update()
}

function hideTooltip() {
  if (tooltipRef.value) {
    tooltipRef.value.style.display = 'none'
    tooltipRef.value.removeAttribute('data-show')
  }
}
</script>

<style lang="scss" scoped>
.tooltipContainer {
  .tooltip {
    &[data-popper-placement^='top'] {
      box-shadow: 2px -2px 4px 1px $black-30;
      > .tooltip__arrow {
        bottom: -4px;
      }
    }

    &[data-popper-placement^='bottom'] {
      box-shadow: 2px 2px 4px 1px $black-30;
      > .tooltip__arrow {
        top: -4px;
      }
    }

    &[data-popper-placement^='left'] {
      box-shadow: 2px 2px 4px 1px $black-30;
      > .tooltip__arrow {
        right: -4px;
      }
    }

    &[data-popper-placement^='right'] {
      box-shadow: -2px 2px 4px 1px $black-30;
      > .tooltip__arrow {
        left: -4px;
      }
    }
  }
}
</style>
